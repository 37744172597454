import './login.css';
import React, { useEffect, useState } from 'react';
import { authenticate, sendEmail } from '../../Controllers/user';
import { getVerify } from '../../Controllers/payment';
import { setCookie } from 'nookies'
import toast, { Toaster } from 'react-hot-toast';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser, faLock, faEyeSlash, faEye } from '@fortawesome/free-solid-svg-icons';
import Loader  from '../../Components/Loader'

function Login() {
    const [email, setEmail] = useState('')
    const [emailReset, setEmailReset] = useState('')
    const [password, setPassword] = useState('')
    const [forgot, setForgot] = useState(false)
    const [showPass, setShowPass] = useState(false)
    const [loaderButton, setLoaderButton] = useState(false)

    async function auth() {
      setLoaderButton(true)
      const data = {
        email: email,
        password: password
      }
    
      try {
        const res = await authenticate(data);
        window.localStorage.setItem("cmenu", true);
        window.localStorage.setItem("name", res.data.user.name);
        window.localStorage.setItem("active", res.data.user.active);
        window.localStorage.setItem("companyName", res.data.user.companyData[0].companyName);
        window.localStorage.setItem("id", res.data.user._id);
        window.localStorage.setItem("organization", res.data.user.organization._id);
        
        setCookie(null, 'token', res.data.token, {
          maxAge: 86400,
          path: '/'
        });
    
        if(res.data.user._id === '63bdecd325bd5a8badd5d80f') {
          window.location.href = "/home";
        }

        const account = await getVerify(res.data?.user?.organization?.customer?.id);
        setLoaderButton(false)
        if (res.data?.user?.isFirstAccess) {
          window.location.href = "/reset";
        } else if (account?.statusSubscription === "canceled" || 
                    account?.statusSubscription === "closed" || 
                      account?.statusSubscription === "stopped" ||
                        account?.statusSubscription === "inactive") {
          toast.error('Não é possível entrar em sua conta. Entre em contato com o suporte para verificar o ocorrido');
        } else {
          window.location.href = "/home";
        }
      } catch (err) {
        setLoaderButton(false)
        console.log('erro ao logar', err);
        toast.error('Verifique seu e-mail e senha');
      }
    }

    const reset = async() => {
      const data = {
        email: emailReset
      }
      await sendEmail(data)
        .then((res) => {
          toast.success('Um e-mail foi enviado para você!!')
          setEmailReset('')
        })
        .catch((err) => {
          console.log('erro ao se registrar', err)
          toast.error('Verifique seu e-mail e senha')
        })
    }

    useEffect(() => {
        window.localStorage.setItem("cmenu", false)
    },[])

  return (
    <div className="container-login">
      <Toaster position="bottom-center" />
        {forgot === false ? (
          <>
            <div className='container-card-login'>
              <div class="screen">
                <div class="screen__content">
                  <div class="login">
                    <div class="login__field">
                      <FontAwesomeIcon className='login__icon fas fa-user' icon={faUser} />
                      <input 
                        onChange={(e) => setEmail(e.target.value)} 
                        type='email' 
                        placeholder='E-mail' 
                        className='login__input' 
                        value={email} 
                      />
                    </div>
                    <div class="login__field">
                      <FontAwesomeIcon className='login__icon fas fa-lock' icon={faLock} />
                      <input 
                        onChange={(e) => setPassword(e.target.value)} 
                        type={showPass ? 'text' : 'password'} 
                        placeholder='Senha' 
                        className='login__input' 
                      />
                      <FontAwesomeIcon 
                        onClick={() =>  setShowPass(!showPass)} 
                        className='login__icon_eye' icon={showPass? faEyeSlash : faEye} />
                    </div>
                    <div className='container-inputs-button'>
                      <button onClick={auth} className='button login__submit'>
                      {loaderButton ? 
                        <Loader size='sm' /> 
                      : 'Entrar'}
                      </button>
                    </div>
                    <div onClick={() => setForgot(true)} >
                        <p className='text-forgot'>Esqueci minha senha</p>
                    </div>
                  </div>
                </div>
                <div class="screen__background">
                  <span class="screen__background__shape screen__background__shape1"></span>
                </div>		
              </div>
            </div>
          </>
        ):(
          <>
              <div class="screen">
                <div class="screen__content">
                  <div class="login">
                    <div class="login__field">
                      <FontAwesomeIcon className='login__icon fas fa-user' icon={faUser} />
                      <input 
                        onChange={(e) => setEmailReset(e.target.value)} 
                        type='email' 
                        placeholder='E-mail' 
                        className='login__input' 
                        value={emailReset} 
                      />
                    </div>
                  
                    <div className='container-inputs-button'>
                      <button onClick={reset} className='button login__submit'>Enviar</button>
                    </div>
                    <div onClick={() => setForgot(false)} >
                        <p className='text-forgot'>Voltar ao login</p>
                    </div>
                  </div>
                </div>
                <div class="screen__background">
                  <span class="screen__background__shape screen__background__shape1"></span>
                </div>		
              </div>
          </>
        )}

      <div className='container-data-enterprise'>
        <span className='text-developed'>Desenvolvido por</span>
        <span className='text-name-company'>Gerenciei</span>
      </div>
    </div>
  );
}

export default Login;